<template>
  <div id="order-view-action-download">
    <b-overlay :show="fullOrderPdfLoading">
      <b-button
        id="order-view-action-download-btn"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        class="mb-75"
        block
        @click="generateFullOrderPdf()"
      >
        <feather-icon icon="DownloadIcon" size="20" class="align-middle" />
        <span class="ml-25 align-middle"> {{ $t('action.download') }} </span>
      </b-button>
    </b-overlay>
    <SupplyDocumentPdf
      ref="full-order-pdf"
      :supply="quoteSelected.quote"
      :title="quoteTitle"
      :invoice-configuration="quoteSelected.invoiceConfiguration"
      @supply-pdf-loading="fullOrderPdfLoading = $event"
    >
      <template #additional>
        <section class="pdf-item">
          <OrderViewCancellationPolicies :print-mode="true" />
        </section>
      </template>
    </SupplyDocumentPdf>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'OrderViewActionDownload',

  components: {
    SupplyDocumentPdf: () => import('@/components/SupplyDocumentPdf.vue'),
    SupplyDocument: () => import('@/components/SupplyDocument.vue'),
    OrderViewCancellationPolicies: () => import('@/views/order/OrderViewCancellationPolicies.vue'),
  },

  data() {
    return {
      fullOrderPdfLoading: false,
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'quoteTitle']),
  },

  methods: {
    generateFullOrderPdf() {
      this.$refs['full-order-pdf'].generateReport()
    },
  },
}
</script>
